import { callsSelectors } from './slice';

export const getGroupId = state => state.global.groupList.selectedGroup;

export const getPage = state => state.parked;

export const getLoading = state => state.parked.isLoading;

export const getLoaded = state => state.activeCalls.loaded;

export const getSuccessMessage = state => state.parked.successMessage;

export const getError = state => state.parked.error;

export const getParkedCallsSearch = state => state.parked.search;

export const getParkedCallsList = state => ({
  columns: state.parked.list.columns,
  list: callsSelectors.selectAll(state.parked.list),
});

export const getParkedCallsPage = state => state.parked.page;

export const getParkedCallsTotalCount = state => state.parked.totalCount;
