
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';

import { ParkedCallsList } from './ParkedCallsList';

import { useParkedCallsActions } from '../../state/hooks';
import { getGroupId, getLoading, getLoaded } from '../../state/selectors';

export const ParkedCalls = ({ state, refreshTable, onSelect, onRemoveSelected, onUnparkSelected, onUnparkRow, setQueryData, setRefreshTable }) => {
  const groupId = useSelector(getGroupId);
  const isLoading = useSelector(getLoading);
  const isLoaded = useSelector(getLoaded);

  const { getParkedCalls, removeParkedCall, unparkParkedCall } = useParkedCallsActions();

  const handleUnparkParkedCall = (data) => {
    unparkParkedCall(data).then(() => {
      onUnparkRow();
    });
  }

  return (
    <ParkedCallsList
      groupId={groupId}
      isLoading={isLoading}
      isLoaded={isLoaded}
      handleGetParkedCalls={getParkedCalls}
      onSelect={onSelect}
      onRemoveSelected={onRemoveSelected}
      onUnparkSelected={onUnparkSelected}
      onRemove={removeParkedCall}
      onUnpark={handleUnparkParkedCall}
      refreshTable={refreshTable}
      setRefreshTable={setRefreshTable}
      setQueryData={setQueryData}
      state={state}
    />
  );
};
