import React from 'react';

import SEO from 'src/components/seo';
import ParkedCalls from 'src/features/parkedCalls';

export default function Onboarding() {
  return (
    <>
      <SEO title="Parked calls" />

      <ParkedCalls />
    </>
  );
}
