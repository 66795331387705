import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLoading, getGroupId, getParkedCallsList, getParkedCallsPage } from './selectors';
import { actions } from './slice';

export const useParkedCallsActions = () => {
  const dispatch = useDispatch();

  const calls = useSelector(getParkedCallsList);
  const isLoading = useSelector(getLoading);

  const getParkedCalls = useCallback((params) => dispatch(actions.getParkedCalls(params)), [dispatch]);
  const setParkedCallsPage = useCallback(page => dispatch(actions.setPage(page)), [dispatch]);
  const removeParkedCall = useCallback(callId => dispatch(actions.removeParkedCall(callId)), [dispatch]);
  const removeParkedCalls = useCallback(data => dispatch(actions.removeParkedCalls(data)), [dispatch]);
  const unparkParkedCall = useCallback(data => dispatch(actions.unparkParkedCall(data)), [dispatch]);
  const unparkParkedCalls = useCallback(data => dispatch(actions.unparkParkedCalls(data)), [dispatch]);
  const updateList = useCallback(items => dispatch(actions.updateList(items)), [dispatch]);
  const clearError = useCallback(() => dispatch(actions.clearError()), [dispatch]);
  const clearSuccessMessage = useCallback(() => dispatch(actions.clearSuccessMessage()), [dispatch]);
  const getParkedCallsData = useCallback(() => dispatch(actions.getParkedCallsData()), [dispatch]);
  const importParkedCallsData = useCallback((data) => dispatch(actions.importParkedCallsData(data)), [dispatch]);

  return {
    calls,
    isLoading,

    setParkedCallsPage,
    removeParkedCall,
    removeParkedCalls,
    unparkParkedCall,
    unparkParkedCalls,
    updateList,
    importParkedCallsData,
    getParkedCallsData,
    getParkedCalls,
    clearError,
    clearSuccessMessage,
  };
};

export const useFetchParkedCalls = () => {
  const dispatch = useDispatch();
  const groupId = useSelector(getGroupId);
  const page = useSelector(getParkedCallsPage);

  useEffect(() => {
    dispatch(actions.getParkedCalls({ groupId, page }));
  }, [dispatch, groupId, page]);
};
