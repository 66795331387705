/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MTableToolbar, MTableActions } from '@material-table/core';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';

import MaterialTable from 'src/components/materialTable';
import checkRole from 'src/helpers/aclHelper';
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

import Loader from 'src/images/loader1.gif';

import { getMe } from 'src/features/users/state/selectors';

const useStyles = makeStyles(theme => ({
  toolbar: {
    '& .MuiToolbar-root': {
      padding: theme.spacing(2, 3),
    },
  },
  row: {
    alignItems: 'center',

    '& .MuiIconButton-root': {
      fontSize: 'inherit',
    },
    '& .MuiIconButton-root:disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'transparent',
      cursor: 'default',
      pointerEvents: 'none',
    },
    '& .MuiIconButton-root .button-unpark': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFF',
      borderRadius: '4px',
      fontSize: 'inherit',
      fontWeight: 700,
      padding: '12px',
      overflow: 'visible',
      textAlign: 'center',
    },
    '& .MuiIconButton-root:disabled .button-unpark': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: 'rgba(0, 0, 0, 0.4)'
    },
  },
  overlayLoading: {
    backdropFilter: 'blur(3px)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export const ParkedCallsList = ({
  groupId, isLoading, isLoaded, state,
  handleGetParkedCalls,
  onSelect,
  onRemove,
  onUnpark,
  onRemoveSelected,
  onUnparkSelected,
  refreshTable,
  setRefreshTable,
  setQueryData,
}) => {
  const classes = useStyles();

  const tableRef = React.createRef();
  const isMounted = useRef(false);

  const userData = useSelector(getMe);

  let timerId = null;

  const [searchOption, setSearchOption] = useState(true);
  const [searchText, setSearchText] = useState(null);
  const [data, setData] = useState([]);
  const [timer, setTimer] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [components, setComponents] = useState(null);
  const [interval, setInterval] = useState(1000);
  const [maxTableSize, setMaxTableSize] = useState(10);

  const dataKeys = [
    { dataKey: 'id', name: 'Id', hidden: true },
    { dataKey: 'name', name: 'Lead Name', cellStyle: { fontWeight: 700 } },
    { dataKey: 'phone', name: 'Lead Number', sorting: false },
    { dataKey: 'routing', name: 'Routing Rule' },
    // { dataKey: 'time_zone', name: 'Time zone' },
    // { dataKey: 'nextcall', name: 'Upcoming calls', type: 'date', dateSetting: { format: 'yyyy-MM-dd' }, },
    // { dataKey: 'nextcall', name: 'Upcoming Calls', type: 'date', },
    { dataKey: 'afterhours', name: 'After Hour Lead', width: 200 },
    { dataKey: 'attempt', name: 'Attempt Number', width: 200 },
  ];

  const columns = dataKeys.map(({ dataKey, name, ...dataProps }) => ({
    title: name,
    field: dataKey,
    ...dataProps,
  }));

  const editableColumns = useMemo(() => JSON.parse(JSON.stringify(columns)), [columns]);

  const handleSelectRow = (data) => {
    setSearchOption(!(data.length > 0));

    onSelect(data);
  };

  const handleRowDelete = call => {
    return new Promise(resolve => {
      resolve(onRemove(call.id));
    });
  };

  const handleRowUnpark = call => {
    onUnpark({ ...call });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    timerId = setTimeout(() => {
      if (!isMounted || !isMounted.current) {
        clearTimeout(timerId);
        return;
      }
      if (document.hidden) {
        setInterval(1000);
      } else {
        setInterval(10000);
      }
      setTimer(!timer);
      clearTimeout(timerId);
    }, interval);
  }, [isLoading, isWaiting]);

  useEffect(() => {
    if (document.hidden) {
      setIsWaiting(!isWaiting);
      return;
    }
    tableRef.current.onQueryChange();
  }, [timer]);

  useEffect(() => {
    setComponents()
  }, [isLoading]);

  useEffect(() => {
    if (state?.dataToUnpark === null) {
      tableRef.current.onAllSelected(false);
    }
  }, [state.dataToUnpark]);

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [groupId]);

  useEffect(() => {
    if (refreshTable === true) {
      tableRef.current.onQueryChange();

      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  return (
    <MaterialTable
      tableRef={tableRef}
      title={<Typography variant="h6">Parked Calls</Typography>}
      // isLoading={true}
      isLoading={isLoading}
      components={{
        Toolbar: props => (
          <div className={classes.toolbar}>
            <MTableToolbar {...props} searchFieldVariant="outlined" />
          </div>
        ),
        Actions: (props) => {
          return (
            <div className={classes.row}>
              <MTableActions
                {...props}
                // eslint-disable-next-line react/destructuring-assignment
                // disabled={ props.data?.$$isUnparked === true }
                title=""
              />
            </div>
          );
        },
        OverlayLoading: (props) => {
          return (
            !data.length && isLoading
              ? (
                  userData?.customer?.is_subaccount === false
                    ? (<div className={classes.overlayLoading}><img alt="loader" src={Loader} /></div>)
                    : (
                        <div className={classes.overlayLoading}>
                          <CircularProgress sx={{ color: '#026de5', strokeLinecap: 'round' }} size={50} thickness={3} />
                        </div>
                      )
                )
              : (<div></div>)
          );
        },
      }}
      columns={editableColumns}
      data={(query) => {
        const { page: pageQuery, search, orderBy, orderDirection } = query;
        const pageCurrent = search && searchText !== search ? 0 : pageQuery;

        return handleGetParkedCalls({ groupId, page: pageCurrent, search, orderBy: orderBy?.field || '', orderDirection })
          .then((responce) => {
            const { dataSets, totalCount } = responce.payload.parked;

            setData(dataSets);
            dataSets.forEach((item) => {
              if (item['phone']) {
                item['phone'] = phoneFormatter(item['phone']);
              }
              item['afterhours'] = item['afterhours'] ? 'Yes' : 'No';
            });

            setSearchText(search);

            setQueryData({
              page: pageCurrent,
              search,
              groupId
            });

            setMaxTableSize((totalCount * 1) <= 10 ? ((totalCount * 1) === 0 ? 6 : (totalCount * 1)) : 10);

            return {
              data: JSON.parse(JSON.stringify(dataSets)),
              page: pageCurrent,
              totalCount: totalCount && parseInt(totalCount, 10) >= 0 ? parseInt(totalCount, 10) : 0,
            };
          }
          );
      }}
      onSelectionChange={handleSelectRow}
      options={{
        search: searchOption,
        debounceInterval: 500,
        showTitle: true,
        toolbar: true,
        selection: checkRole(['admin', 'manager']),
        paging: true,
        pageSize: maxTableSize,
        pageSizeOptions: [],
        sorting: true,
        thirdSortClick: false,
      }}
      actions={[
        checkRole(['admin', 'manager']) ? {
          icon: () => (<span className="button-unpark">Unpark</span>),
          name: 'unpark',
          position: 'row',
          tooltip: '',
          onClick: (event, data) => {
            // eslint-disable-next-line no-param-reassign
            data.$$isUnparked = true;

            handleRowUnpark({ ...data });
          },
        } : {},
        {
          icon: () => (<span className="button-unpark">Unpark Selected</span>),
          position: "toolbarOnSelect",
          tooltip: 'When you unpark a lead it will be removed from call queue',
          onClick: () => (onUnparkSelected()),
        },
        {
          icon: () => (<span className="button-unpark">Delete Selected</span>),
          position: "toolbarOnSelect",
          tooltip: 'When you delete a lead it will be removed',
          onClick: () => (onRemoveSelected()),
        },
      ]}
      editable={checkRole(['admin', 'manager']) ? {
        onRowDelete: handleRowDelete,
      } : {}}
      localization={{
        body: {
          deleteTooltip: '',
        },
        header: {
          actions: '',
        },
        toolbar: {
          nRowsSelected: '{0} lead(s) selected',
        },
      }}
    />
  );
};
