import React from 'react';
import { Button, Grid } from '@material-ui/core';

import Tooltip from 'src/components/tooltip';

export default function ({ onOperation, buttonText }) {
  return (
    <Grid item xs md="auto">
      <Tooltip arrow title="">
        <Button variant="outlined" color="primary" size="large" disableElevation fullWidth onClick={onOperation}>
          {buttonText}
        </Button>
      </Tooltip>
    </Grid>
  );
}
