import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Link, makeStyles } from '@material-ui/core';

import { useFileInput } from 'src/hooks/useFileInput';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';

import { useParkedCallsActions } from './state/hooks';
import { getError, getSuccessMessage } from './state/selectors';
import Operation from './operation';

import { ParkedCalls } from './components/list/ParkedCalls';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    padding: theme.spacing(2, 0),
  },
}));

const ParkedCallsPage = () => {
  const classes = useStyles();

  const errors = useSelector(getError);
  const successMessage = useSelector(getSuccessMessage);

  const [_, uploadFile] = useFileInput();

  const [state, setState] = useState({
    dataToUnpark: null,
  });

  const [ queryData, setQueryData ] = useState({});
  const [ refreshTable, setRefreshTable ] = useState(false);
  const [ openRemoveDialog, setOpenRemoveDialog ] = useState(false);
  const [ openUnparkDialog, setOpenUnparkDialog ] = useState(false);
  const [ openErrorsDialog, setOpenErrorsDialog ] = useState(false);
  const [ openSuccessMessageDialog, setOpenSuccessMessageDialog ] = useState(false);

  const {
    clearError,
    clearSuccessMessage,
    isLoading,
    importParkedCallsData,
    getParkedCallsData,
    removeParkedCalls,
    unparkParkedCalls
  } = useParkedCallsActions();

  const handleOpenDeleteSelected = () => {
    setOpenRemoveDialog(true);
  }

  const handleOpenUnparkSelected = () => {
    setOpenUnparkDialog(true);
  }

  const handleSelectCall = data => {
    setState({
      ...state,
      dataToUnpark: data
    });
  };

  const handleRemoveSelected = () => {
    const list = JSON.parse(JSON.stringify(state.dataToUnpark));

    setOpenRemoveDialog(false);

    removeParkedCalls({ ...queryData, list})
      .then(() => {
        setState({ dataToUnpark: null });
        setRefreshTable(true);
      });
  };

  const handleUnparkSelected = () => {
    const list = JSON.parse(JSON.stringify(state.dataToUnpark));

    setOpenUnparkDialog(false);

    unparkParkedCalls({ ...queryData, list })
      .then(() => {
        setState({ dataToUnpark: null });
        setRefreshTable(true);
      });
  };

  const handleUnparkRow = () => {
    setRefreshTable(true);
  };

  const handleClearError = () => {
    setOpenErrorsDialog(false);
    clearError();
  };

  const handleClearSuccessMessage = () => {
    setOpenSuccessMessageDialog(false);
    clearSuccessMessage();
  };

  const handleImport = () => {
    uploadFile({ accept: '.csv', formData: true }, (source) => {
      importParkedCallsData({ ...queryData, source });
    });
  };

  useEffect(() => {
    setOpenSuccessMessageDialog(false);
    setOpenErrorsDialog(false);
  },[]);

  useEffect(() => {
    if (!successMessage || successMessage < 1) {
      return;
    }

    setOpenSuccessMessageDialog(true);
  },[successMessage]);

  useEffect(() => {
    if (!errors) {
      return;
    }

    setOpenErrorsDialog(true);
  },[errors]);

  return (
    <LoadingContainer loading={isLoading} height={'auto'}>
      <ParkedCalls
        onSelect={handleSelectCall}
        onRemoveSelected={handleOpenDeleteSelected}
        onUnparkSelected={handleOpenUnparkSelected}
        onUnparkRow={handleUnparkRow}
        refreshTable={refreshTable}
        setQueryData={setQueryData}
        setRefreshTable={setRefreshTable}
        state={state}
      />

      <div className={classes.buttonWrapper}>
        <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
          <Grid item xs md="auto">
            <Link href="https://s3.us-west-2.amazonaws.com/s2l.static/templates/import_template.csv">Download Lead Upload Template</Link>
          </Grid>
          <Operation onOperation={handleImport} buttonText="Import" />
          <Operation onOperation={getParkedCallsData} buttonText="Export" />
        </Grid>
      </div>

      <Dialog
        open={openSuccessMessageDialog}
        onClose={handleClearSuccessMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success!</DialogTitle>

        <DialogContent>
          <DialogContentText color="secondary">{successMessage} lines was uploaded!</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            onClick={handleClearSuccessMessage}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openErrorsDialog}
        onClose={handleClearError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error!</DialogTitle>

        <DialogContent>
          {
            (errors && !Array.isArray(errors))
              ? (<DialogContentText color="secondary">{errors}</DialogContentText>)
              : (errors)
                ? errors.map((error, indexError) =>
                  (<DialogContentText key={`error-${indexError}`} color="secondary">Line {error.line + 1}: {error.message}</DialogContentText>))
                : (<></>)
          }
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            onClick={handleClearError}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRemoveDialog}
        onClose={() => {
          setOpenRemoveDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>

        <DialogContent>
          <DialogContentText color="secondary">Are you sure you want to delete selected parked call(s)</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            onClick={() => {
              setOpenRemoveDialog(false);
            }}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={() => {
              setOpenRemoveDialog(false);
              handleRemoveSelected();
            }}
          >
            Delete all
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUnparkDialog}
        onClose={() => {
          setOpenUnparkDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning!</DialogTitle>

        <DialogContent>
          <DialogContentText color="secondary">Are you sure you want to unpark selected parked call(s)</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            disableElevation
            onClick={() => {
              setOpenUnparkDialog(false);
            }}
          >
            Cancel
          </Button>

          <Button
            color="secondary"
            variant="contained"
            disableElevation
            onClick={() => {
              setOpenUnparkDialog(false);
              handleUnparkSelected();
            }}
          >
            Unpark all
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingContainer>
  );
};

export default ParkedCallsPage;
